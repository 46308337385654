import React, { useEffect, useState } from 'react'
import { Modal } from 'antd'
import Button from 'react-bootstrap/Button'
import { getParticipantDetails, openNotificationWithIcon } from '../../../utils'

export const AutomaticCallConnectModal = ({ openAutomaticCallModal, setAutomaticCallModal, disconnectedUserType, jitsiApi, job, userType }) => {
	const [timer, setTimer] = useState(() => {
		if (!job?.meeting_start_time) {
			return 30
		}
		return 10
	})
	const [popUpAlreadyShown, setPopupAlreadyShown] = useState(false)
	const [userName, setUserName] = useState('')
	const [isTimerPaused, setIsTimerPaused] = useState(false)
	const [timeoutId, setTimeoutId] = useState(null)

	useEffect(() => {
		if (job?.meeting_start_time && jitsiApi) {
			participantLength()

			const interval = setInterval(() => {
				participantLength()
			}, 5000)

			return () => clearInterval(interval)
		}
	}, [job, jitsiApi])

	const participantLength = async () => {
		const partisipantsArrInitial = await getParticipantDetails(jitsiApi, userType)

		const hasTechnician = partisipantsArrInitial?.some(participant =>
			participant.role === 'technician' ||
			(job?.technician?.user?.firstName &&
				participant.displayName?.includes(job.technician.user.firstName))
		)

		const hasCustomer = partisipantsArrInitial?.some(participant =>
			participant.role === 'customer' ||
			(job?.customer?.user?.firstName &&
				participant.displayName?.includes(job.customer.user.firstName))
		)

		if (hasTechnician && hasCustomer) {
			setAutomaticCallModal(false)
			window.sessionStorage.removeItem('alreadyInCallPopUpOpen')
		}
	}

	useEffect(() => {
		if (job && disconnectedUserType) {
			let fullName = ''
			if (disconnectedUserType == 'technician') {
				fullName = job?.technician?.user?.firstName + ' ' + job?.technician?.user?.lastName
			}
			if (disconnectedUserType == 'customer') {
				fullName = job?.customer?.user?.firstName + ' ' + job?.customer?.user?.lastName
			}

			if (fullName == '') {
				const userType = disconnectedUserType == 'technician' ? 'Technician' : 'Customer'
				fullName = userType
			}
			setUserName(fullName)
		}
	}, [job, disconnectedUserType])

	useEffect(() => {
		const alreadyPopUpShown = window.sessionStorage.getItem('alreadyInCallPopUpOpen')
		if (alreadyPopUpShown == 'yes') {
			setPopupAlreadyShown(true)
		} else {
			if (timer > 0 && !isTimerPaused) {
				let intervalId = setInterval(() => {
					setTimer((prevTimer) => prevTimer - 1)
				}, 1000)

				return () => clearInterval(intervalId)
			}
			if (timer == 0) {
				CallAnotherParticiapant()
			}
		}
	}, [openAutomaticCallModal, timer, isTimerPaused])

	const CallAnotherParticiapant = () => {
		let finalPhoneNumber = ''
		if (disconnectedUserType == 'technician') {
			finalPhoneNumber = job?.technician?.profile?.confirmId?.phoneNumber
		}
		if (disconnectedUserType == 'customer') {
			finalPhoneNumber = job?.customer?.phoneNumber
		}
		if (finalPhoneNumber && finalPhoneNumber != '') {
			jitsiApi.invite([
				{
					allowed: true,
					number: finalPhoneNumber,
					originalEntry: finalPhoneNumber,
					showCountryCodeReminder: false,
					type: 'phone',
				},
			])
			if (job?.meeting_start_time) {
				window.sessionStorage.setItem(`alreadyInCallPopUpOpen`, 'yes')
			}
			openNotificationWithIcon('success', 'Successfully Invited')
		}
		setAutomaticCallModal(false)
	}

	const handleCancelTimer = () => {
		setIsTimerPaused(true)
		setAutomaticCallModal(false)

		// Clear any existing timeout
		if (timeoutId) {
			clearTimeout(timeoutId)
		}

		// Set new timeout to show modal after 30 seconds
		const newTimeoutId = setTimeout(() => {
			setIsTimerPaused(false)
			setTimer(30)
			setAutomaticCallModal(true)
		}, 30000)

		setTimeoutId(newTimeoutId)
	}

	const offAutomaticCallConnectModal = () => {
		setAutomaticCallModal(false)
	}

	return (
		<Modal
			visible={true}
			zIndex={5}
			onCancel={offAutomaticCallConnectModal}
			className="add-card-modal-outer d-flex align-items-center justify-content-sm-center p-5 microphone-modal-style"
			width={800}
			maskClosable={false}
			destroyOnClose={true}
			closable={false}
			bodyStyle={{
				zIndex: 2,
			}}
			footer={
				<>
					{!popUpAlreadyShown && (
						<Button
							variant="outline-warning warning-button-style"
							onClick={handleCancelTimer}
						>
							Wait
						</Button>
					)}
					{job?.meeting_start_time ? (
						<Button
							variant="outline-info info-button-style"
							onClick={offAutomaticCallConnectModal}
						>
							Cancel
						</Button>
					) : null}
					<Button
						variant="outline-success success-button-style"
						onClick={CallAnotherParticiapant}
					>
						{popUpAlreadyShown ? 'Call' : `Call ( ${timer} ) `}
					</Button>
				</>
			}
		>
			{!job?.meeting_start_time ? (
				<p className="automatic-call-p-style" style={{ textAlign: 'center' }}>{`${userName ? userName : 'User'
					} is connecting. If connection fails, the system will automatically call within 30 seconds.`}</p>
			) : (
				<p className="automatic-call-p-style">{`Oops ! It seems like your ${disconnectedUserType} got disconnected. We are doing our best to reconnect ${disconnectedUserType}. In the meantime please call your ${disconnectedUserType} using the call button.`}</p>
			)}
		</Modal>
	)
}